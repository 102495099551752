import * as React from 'react';
import { AppProps, SeoObject } from 'src/app/types';
import { AppPageLayout, AppPageLinks } from 'src/app.consumer/components';
import { Localized, LocalizedLink } from 'src/app/components';

const seo: SeoObject = {
  title: 'Imprint Page',
};

const ImprintPage = (props: AppProps) => {
  return (
    <AppPageLayout
      sidebar={<AppPageLinks />}
      subTitle={<Localized dictKey={'staticLocales:pages./imprint.subtitle'} />}
      title={<Localized dictKey={'staticLocales:pages./imprint.link label'} />}
      {...{ ...props, seo }}
    >
      <>
        <p className='text-p2 my-4'>
          <Localized dictKey={'staticLocales:pages./imprint.company name'} />
        </p>
        <p className='text-p2 my-4'>
          <Localized dictKey={'staticLocales:pages./imprint.company address street'} /> <br />
          <Localized dictKey={'staticLocales:pages./imprint.company address city'} />
        </p>
        <p className='text-p2 my-4'>
          <Localized dictKey={'staticLocales:pages./imprint.district court'} /> <br />
          <Localized dictKey={'staticLocales:pages./imprint.vat id'} />
        </p>
        <p className='text-p2 my-4'>
          <Localized dictKey={'staticLocales:pages./imprint.bank name'} /> <br />
          <Localized dictKey={'staticLocales:pages./imprint.iban'} /> <br />
          <Localized dictKey={'staticLocales:pages./imprint.bic code'} />
        </p>
        <p className='text-p2 my-4'>
          <Localized dictKey={'staticLocales:pages./imprint.e-mail contact'} />{' '}
          <Localized className='font-semibold' dictKey={'staticLocales:pages./imprint.e-mail address'} />
          <br />
          <Localized dictKey={'staticLocales:pages./imprint.customer service'} />{' '}
          <LocalizedLink href={'/contact'} className='!text-accent-500 !text-p2 underline dark:text-accent-500'>
            <Localized dictKey={'staticLocales:pages./imprint.customer service url'} />
          </LocalizedLink>
        </p>

        <p className='text-p2 my-4'>
          <Localized dictKey={'staticLocales:pages./imprint.executive director'} />
        </p>
      </>
    </AppPageLayout>
  );
};

export default ImprintPage;
